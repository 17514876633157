import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class RefService {

  constructor(private ref:AngularFireDatabase) { }

  get baseRef(){
    return this.ref.database.ref()
  }

  get version(){
    return 'v.1.5.1'
  }

  get usersRef(){
    return this.baseRef.child('users')
  }
  get usersTokensRef(){
    return this.baseRef.child('custom-tokens')
  }
  userRef(user:string){
    return this.baseRef.child('users').child(user)
  }
  userStatusRef(userId:string){
    return this.baseRef.child('users-status').child(userId)
  }


  campaignsCompleteRef(uid,id){
    return this.ref.database.ref().child('campaigns').child(id).child(uid)
  }


  usersImportList(){
    return this.ref.database.ref().child('users-import')
  }

  get messagesList(){
    return this.ref.database.ref().child('messages')
  }
  get messagesStatusList(){
    return this.ref.database.ref().child('messageStatus')
  }
  messagesStatusFilterList(status){
    return this.ref.database.ref().child('messageStatusFilter').child(status)
  }
  messageObj(idMessage:string){
    return this.messagesList.child(idMessage)
  }

  get modulesRef(){
    return this.ref.database.ref().child('modules')
  }
  get parametersRef(){
    return this.ref.database.ref().child('parameters')
  }

  get blurOffensiveMaterialRef(){
    return this.parametersRef.child('blurOffensiveMaterial')
  }
  get enableSanitizationRef(){
    return this.parametersRef.child('enableSanitization')
  }
  get moderateMessagesRef(){
    return this.parametersRef.child('moderateMessages')
  }
  get logoRef(){
    return this.ref.database.ref().child('logo')
  }

  moodsList(uid){
    return this.ref.database.ref().child('moods').child(uid)
  }
  get anonMoodsList(){
    return this.ref.database.ref().child('anonMoods')
  }

  get statistics(){
    return this.ref.database.ref().child('statistics')
  }

  get statisticsFeedbacks(){
    return this.statistics.child('feedbacks')
  }

  statisticsUsersFeedbacks(uid){
    return this.ref.database.ref().child('statistics').child('feedbacks').child('users').child(uid).child('received')
  }
  statisticsUsersFeedbacksReceiver(uid){
    return this.ref.database.ref().child('statistics').child('feedbacks').child('users').child(uid).child('received')
  }
  statisticsUsersFeedbacksSent(uid){
    return this.ref.database.ref().child('statistics').child('feedbacks').child('users').child(uid).child('sent')
  }

  statisticsCompetenciesUsersFeedbacks(uid){
    return this.ref.database.ref().child('statistics').child('feedbacks').child('users').child(uid).child('received').child('competencies')
  }

  get statisticsFeedbacksContextAverageSentimentValue(){
    return this.statisticsFeedbacks.child('contextAverageSentimentValue')
  }

  get statisticsFeedbacksContextCount(){
    return this.statisticsFeedbacks.child('contextCount')
  }

  statisticsFeedbacksMonthAverageSentimentValue(year,month){
    return this.statisticsFeedbacks.child('monthAverageSentimentValue').child(year).child(month)
  }

  statisticsFeedbacksMonthTotalCount(year,month){
    return this.statisticsFeedbacks.child('monthTotalCount').child(year).child(month)
  }

  get statisticsFeedbacksTagAverageSentimentValue(){
    return this.statisticsFeedbacks.child('tagAverageSentimentValue')
  }

  get statisticsFeedbacksTagCount(){
    return this.statisticsFeedbacks.child('tagCount')
  }

  statisticsFeedbacksYearMonthlyAverageSentimentValue(year){
    return this.statisticsFeedbacks.child('yearMonthlyAverageSentimentValue').child(year)
  }

  statisticsFeedbacksYearMonthlyTotalCount(year){
    return this.statisticsFeedbacks.child('yearMonthlyTotalCount').child(year)
  }

  get statisticsMessages(){
    return this.statistics.child('messages')
  }
  statisticsMessagesMonthAverageSentimentValue(year,month){
    return this.statisticsMessages.child('monthAverageSentimentValue').child(year).child(month)
  }
  statisticsMessagesMonthTotalCount(year,month){
    return this.statisticsMessages.child('monthTotalCount').child(year).child(month)
  }
  get statisticsMessagesTagAverageSentimentValue(){
    return this.statisticsMessages.child('tagAverageSentimentValue')
  }
  get statisticsMessagesTagCount(){
    return this.statisticsMessages.child('tagCount')
  }
  statisticsMessagesYearMonthlyAverageSentimentValue(year){
    return this.statisticsMessages.child('yearMonthlyAverageSentimentValue').child(year)
  }
  statisticsMessagesYearMonthlyTotalCount(year){
    return this.statisticsMessages.child('yearMonthlyTotalCount').child(year)
  }
  get statisticsMoods(){
    return this.statistics.child('moods')
  }
  statisticsMoodsMonthAverageSentimentValue(year,month){
    return this.statisticsMoods.child('monthAverageSentimentValue').child(year).child(month)
  }
  statisticsMoodsMonthTotalCount(year,month){
    return this.statisticsMoods.child('monthTotalCount').child(year).child(month)
  }
  statisticsMoodsYearMonthlyAverageSentimentValue(year){
    return this.statisticsMoods.child('yearMonthlyAverageSentimentValue').child(year)
  }
  statisticsMoodsYearMonthlyTotalCount(year){
    return this.statisticsMoods.child('yearMonthlyTotalCount').child(year)
  }
  get anonMoodsRef(){
    return this.ref.database.ref().child('anonMoods')
  }
  get moodsRef(){
    return this.ref.database.ref().child('moods')
  }
  get notificationsListRef(){
    return this.ref.database.ref().child('notifications')
  }
  get sendNotificationNowRef(){
    return this.ref.database.ref().child('send-notification-now')
  }
  get quizRef(){
    return this.ref.database.ref().child('quiz')
  }
  get usersImportRef(){
    return this.ref.database.ref().child('imports').child('users')
  }
  get campaignsRef(){
    return this.ref.database.ref().child('v2').child('campaigns')
  }
  get settingsImportUsers(){
    return this.ref.database.ref().child('settings').child("users").child("import-users")
  }
  get settingsMultiCampaings(){
    return this.ref.database.ref().child('settings').child("campaigns").child("multiple-campaigns")
  }
  get tagsRef(){
    return this.ref.database.ref().child('tags')
  }
  get contextsRef(){
    return this.ref.database.ref().child('contexts')
  }
  get feedbacksList(){
    return this.ref.database.ref().child('feedbacks')
  }
  get competenciesRef(){
    return this.ref.database.ref().child('competencies')
  }
  get messagesContextsRef(){
    return this.ref.database.ref().child('settings').child('messages').child('contexts')
  }
  get messagesRecipientsRef(){
    return this.ref.database.ref().child('messageRecipients')
  }
}
