import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ResizeService } from '../../services/resize.service';
import { routerAnimation } from '../../utils/page.animation';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from '../../models/user';
import { DbService } from '../../services/db.service';
import { MatSelectChange, MatAutocompleteSelectedEvent } from '@angular/material';
import { take, startWith, map } from 'rxjs/operators';
import { User2 } from '../../models/user2';
// declare let echarts: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [routerAnimation]
})

export class DashboardComponent implements OnInit, OnDestroy {

  date = new Date()
  contexts = []
  tags = []
  feedbacks
  messages
  moods
  radar
  option

  @HostBinding('@routerAnimation') routerAnimation = true;
  chartInstances = [];
  resizeSubscription;
  // users:User[] = []
  // userCtrl: FormControl;
  // filteredUsers: Observable<User[]>;
  // users

  users:User2[] = []
  // users:User[] = []
  feedbackDescription
  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;

  constructor(public db: DbService, private resizeService: ResizeService) {
    this.stateCtrl = new FormControl();
    this.db.db.list<{}>(this.db.ref.baseRef.child('users')).snapshotChanges()
    // .subscribe(users => this.users = users)
    // this.db.usersSnapList.
    .pipe(take(1)).subscribe(users => {
      for(let user of users){
        this.users.push({
          uid:user.payload.child('userId').val(),
          name:user.payload.child('userName').val(),
          photoUrl: user.payload.child('avatar_url').val(),
          email: user.payload.child('email').val()
        })
      }

      this.filteredStates = this.stateCtrl.valueChanges
      .pipe(startWith(''), map(user => user ? this.filterStates(user) : this.users.slice()));
    })
    this.resizeSubscription = resizeService.resizeInformer$.subscribe(
      () => this.chartInstances.forEach((chart) => {
        chart.resize()
        chart.setOptions()
      })
    );
  }

  async ngOnInit() {

      await this.contextCountFeedbacks()
      await this.tagCountFeedbacks()
      await this.yearMonthlyTotalCountFeedbacks()
      await this.contextAverageSentimentValueFeedbacksRadar()
      await this.tagAverageSentimentValueFeedbacksRadar()
      await this.last15MoodsBySentimentValue()

      setTimeout( async ()=> {
        await this.tagAverageSentimentValueFeedbacksRadar()
        await this.contextAverageSentimentValueFeedbacksRadar()
      }
      , 5000)
  }
  
  filterStates(name: string) {
    return this.users.filter(user =>
      user['name'].toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }

  contextCountFeedbacks(){
    this.db.contextCountFeedbacks().subscribe(contexts => this.contexts = contexts)
  }
  contextAverageSentimentValueFeedbacks(){
    this.db.contextAverageSentimentValueFeedbacks().subscribe(contexts => this.contexts = contexts)
  }
  tagCountFeedbacks(){
    this.db.tagCountFeedbacks().subscribe(tags => this.tags = tags)
  }
  tagAverageSentimentValueFeedbacks(){
    this.db.tagAverageSentimentValueFeedbacks().subscribe(tags => this.tags = tags)
  }
  yearMonthlyTotalCountFeedbacks(){
    this.db.yearMonthlyTotalCountFeedbacks(this.date.getFullYear().toString()).subscribe(feedbacks => this.feedbacks = feedbacks)
  }
  yearMonthlyAverageSentimentValueFeedbacks(){
    this.db.yearMonthlyAverageSentimentValueFeedbacks(this.date.getFullYear().toString()).subscribe(feedbacks => this.feedbacks = feedbacks)
  }
  monthTotalCountFeedbacks(){
    this.db.monthTotalCountFeedbacks(this.date.getFullYear().toString(),this.date.getMonth().toString()).subscribe(feedbacks => this.feedbacks = feedbacks)
  }
  monthAverageSentimentValueFeedbacks(){
    this.db.monthAverageSentimentValueFeedbacks(this.date.getFullYear().toString(),this.date.getMonth().toString()).subscribe(feedbacks => this.feedbacks = feedbacks)
  }
  last15FeedbacksBySentimentValue(){
    this.db.last15FeedbacksBySentimentValue().subscribe(feedbacks => this.feedbacks = feedbacks)
  }
  async contextAverageSentimentValueFeedbacksRadar(){
     await this.db.contextAverageSentimentValueFeedbacksRadar().subscribe(async indicadores => {
      // this.radar = radar
      let contexts = []
      let contexts_values = []
      for await(let indicator of indicadores){
        contexts.push({text: indicator.text, max: 1})
         await this.db.ref.statisticsFeedbacksContextAverageSentimentValue.child(indicator.id).on('value',(snap) => {
          contexts_values.push(snap.val())
        })
      }
      // console.log(contexts_values)
      this.radar = {
          title : {
              text: '',
              subtext: ''
          },
          tooltip : {
              trigger: 'axis'
          },
          legend: {
              orient : 'vertical',
              x : 'right',
              y : 'bottom'
          },
          polar : [
             {
                 indicator : contexts
              }
          ],
          calculable : true,
          series : [
              {
                  name: 'All',
                  type: 'radar',
                  data : [
                      {
                          value : contexts_values
                      }
                  ]
              }
          ]
      };
    })
  }
  tagAverageSentimentValueFeedbacksRadar(){
    this.db.tagAverageSentimentValueFeedbacksRadar().subscribe(indicadores => {

      let tags = []
      let tag_values = []
      for(let indicator of indicadores){
        tags.push({text: indicator.text, max: 1})
        this.db.ref.statisticsFeedbacksTagAverageSentimentValue.child(indicator.id).on('value',snap => {
          tag_values.push(snap.val())
        })
      }
      // console.log(tag_values)
      this.radar =  {
          title : {
              text: '',
              subtext: ''
          },
          tooltip : {
              trigger: 'axis'
          },
          legend: {
              orient : 'vertical',
              x : 'right',
              y : 'bottom'
          },
          polar : [
             {
                 indicator : tags
              }
          ],
          calculable : true,
          series : [
              {
                  name: 'All',
                  type: 'radar',
                  data : [
                      {
                          value : tag_values
                      }
                  ]
              }
          ]
      };
    })
  }
  tagAverageSentimentValueMessagesRadar(){
    this.db.tagAverageSentimentValueMessagesRadar().subscribe(contexts => this.radar = contexts)
  }

  // Moods
  monthAverageSentimentValueMoods(){
    this.db.monthAverageSentimentValueMoods(this.date.getFullYear().toString(),this.date.getMonth().toString()).subscribe(moods => this.moods = moods)
  }
  monthTotalCountMoods(){
    this.db.monthTotalCountMoods(this.date.getFullYear().toString(),this.date.getMonth().toString()).subscribe(moods => this.moods = moods)
  }
  last15MoodsBySentimentValue(){
    this.db.last15MoodsBySentimentValue().subscribe(moods => this.moods = moods)
  }
  yearMonthlyTotalCountMoods(){
    this.db.yearMonthlyTotalCountMoods(this.date.getFullYear().toString()).subscribe(moods => this.moods = moods)
  }
  yearMonthlyAverageSentimentValueMoods(){
    this.db.yearMonthlyAverageSentimentValueMoods(this.date.getFullYear().toString()).subscribe(moods => this.moods = moods)
  }

  // Messages
  monthAverageSentimentValueMessages(){
    this.db.monthAverageSentimentValueMessages(this.date.getFullYear().toString(),this.date.getMonth().toString()).subscribe(messages => this.messages = messages)
  }
  monthTotalCountMessages(){
    this.db.monthTotalCountMessages(this.date.getFullYear().toString(),this.date.getMonth().toString()).subscribe(messages => this.messages = messages)
  }
  last15MessagesBySentimentValue(){
    this.db.last15MessagesBySentimentValue().subscribe(messages => this.messages = messages)
  }
  yearMonthlyTotalCountMessages(){
    this.db.yearMonthlyTotalCountMessages(this.date.getFullYear().toString()).subscribe(messages => this.messages = messages)
  }
  yearMonthlyAverageSentimentValueMessages(){
    this.db.yearMonthlyAverageSentimentValueMessages(this.date.getFullYear().toString()).subscribe(messages => this.messages = messages)
  }
  selected(event:MatAutocompleteSelectedEvent){
    this.option = null
    this.db.statisticsUsersFeedbacksSentimentValueReceivedObj(event.option.id).subscribe(received => {
    this.db.statisticsUsersFeedbacksSentimentValueSentObj(event.option.id).subscribe(sent => {

      let receivedAwsomeCount = 0
      let receivedGoodCount = 0
      let receivedAverageCount = 0
      let receivedBadCount = 0
      let receivedWorstCount = 0

      if(received != null){
        receivedAwsomeCount = received['awsomeCount']
        receivedGoodCount = received['goodCount']
        receivedAverageCount = received['averageCount']
        receivedBadCount = received['badCount']
        receivedWorstCount = received['worstCount']
      }

      let sentAwsomeCount = 0
      let sentGoodCount = 0
      let sentAverageCount = 0
      let sentBadCount = 0
      let sentWorstCount = 0

      if(sent != null){
        sentAwsomeCount = sent['awsomeCount']
        sentGoodCount = sent['goodCount']
        sentAverageCount = sent['averageCount']
        sentBadCount = sent['badCount']
        sentWorstCount = sent['worstCount']
      }
      this.option = {
        // backgroundColor: "#344b58",
        backgroundColor: "#FFFFFF",
        "title": {
            x: "4%",

            textStyle: {
                color: '#fff',
                fontSize: '22'
            },
            subtextStyle: {
                color: '#90979c',
                fontSize: '16',

            },
        },
        "tooltip": {
            "trigger": "axis",
            "axisPointer": {
                "type": "shadow",
                textStyle: {
                    color: "#fff"
                }

            },
        },
        "grid": {
            "borderWidth": 0,
            "top": 110,
            "bottom": 95,
            textStyle: {
                color: "#fff"
            }
        },
        "legend": {
            x: '4%',
            top: '11%',
            textStyle: {
                color: '#90979c',
            },
            "data": ['Enviados', 'Recebidos']
        },
        "calculable": true,
        "xAxis": [{
            "type": "category",
            "axisLine": {
                lineStyle: {
                    color: '#90979c'
                }
            },
            "splitLine": {
                "show": false
            },
            "axisTick": {
                "show": false
            },
            "splitArea": {
                "show": false
            },
            "axisLabel": {
                "interval": 0,

            },
            "data": ["Ótimo","Bom","Regular","Ruim","Péssimo"],
        }],
        "yAxis": [{
            "type": "value",
            "splitLine": {
                "show": false
            },
            "axisLine": {
                lineStyle: {
                    color: '#90979c'
                }
            },
            "axisTick": {
                "show": false
            },
            "axisLabel": {
                "interval": 0,

            },
            "splitArea": {
                "show": false
            },

        }],
        "dataZoom": [{
            "show": false,
            "height": 30,
            "xAxisIndex": [
                0
            ],
            bottom: 30,
            "start": 10,
            "end": 80,
            handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
            handleSize: '110%',
            handleStyle:{
                color:"#d3dee5",
                
            },
              textStyle:{
                color:"#fff"},
              borderColor:"#90979c"
            
            
        }, {
            "type": "inside",
            "show": true,
            "height": 15,
            "start": 1,
            "end": 35
        }],
        "series": [{
                "name": "Recebidos",
                "type": "bar",
                "stack": "Recebidos",
                "barMaxWidth": 35,
                "barGap": "10%",
                "itemStyle": {
                    "normal": {
                        "color": "rgba(255,144,128,1)",
                        "label": {
                            "show": true,
                            "textStyle": {
                                "color": "#fff"
                            },
                            "position": "insideTop",
                            formatter: function(p) {
                                return p.value > 0 ? (p.value) : '';
                            }
                        }
                    }
                },
                "data": [
                  receivedAwsomeCount,
                  receivedGoodCount, 
                  receivedAverageCount,
                  receivedBadCount,
                  receivedWorstCount
                ],
            },
            {
              "name": "Enviados",
              "type": "bar",
              "stack": "Enviados",
              "itemStyle": {
                  "normal": {
                      "color": "rgba(0,191,183,1)",
                      "barBorderRadius": 0,
                      "label": {
                          "show": true,
                          "position": "top",
                          formatter: function(p) {
                              return p.value > 0 ? (p.value) : '';
                          }
                      }
                  }
              },
              "data": [
                sentAwsomeCount,
                sentGoodCount, 
                sentAverageCount,
                sentBadCount,
                sentWorstCount
              ]
            }
    ]}
    })
    })
  }
}
