// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBOh7lMN38UPqRuGHD9V1zUsYxYQV3_H4U",
    authDomain: "zeiss-insights.firebaseapp.com",
    databaseURL: "https://zeiss-insights.firebaseio.com",
    projectId: "zeiss-insights",
    storageBucket: "zeiss-insights.appspot.com",
    messagingSenderId: "404570135801"
  }
};
