import {Component, HostBinding} from '@angular/core';
import {MatIconRegistry} from '@angular/material';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

// declare let ga: any;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  @HostBinding('class.loading') loading = true;

  constructor(mdIconRegistry: MatIconRegistry, 
              public router: Router, 
              translateService: TranslateService) {
    // mdIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });
    this.loading = false;
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use('en');
  }

}
